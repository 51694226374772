body {
  height: 100vh;
  box-sizing: border-box;
  background: #161c20;
  font-family: Helvetica Neue, sans-serif;
  line-height: 1.5;
}

.menu {
  flex-direction: row;
  justify-content: end;
  align-items: center;
  display: flex;
}

.main {
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.main #classify-input {
  width: 400px;
  height: 20px;
  background-color: #f2f4f3;
  border: none;
  outline: none;
  padding: 10px;
}

.main #classify-input:focus {
  outline: none;
}

.main #classify-btn {
  width: 137px;
  height: 42px;
  cursor: pointer;
  background-color: #f2f4f3;
  border: none;
  margin-left: 35px;
  font-size: 14px;
}

.main #classify-btn:hover {
  background-color: #f1d1b4;
}

.main #classify-btn:active {
  background-color: #ffbd6a;
}

.message {
  width: 100%;
  text-align: center;
  color: #f2f4f3;
  margin-top: 30px;
  position: absolute;
  top: 53%;
  left: 0;
}

/*# sourceMappingURL=index.6a54e97c.css.map */
